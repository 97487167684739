html, body {
	position: absolute;
  width: 100%;
  height: 100%;
  background: url("./images/GOnet_Region_Selection.png") no-repeat center center fixed;
  background-size: contain;
  display: flex;
}

* {
  margin: 0;
  padding: 0;
}

.parent {
  margin: auto;
  background-color: #000;
  display: flex;
  height: 80%;
  width: 80%;
}

.parent:hover {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0%;
	top: 0%;
	background: url("./images/GOnet_Region_Selection2.png") no-repeat center center fixed;
	background-size: contain;
	display: flex;
}

.child {
  margin: auto;  /* Magic! */
  max-width: 100%;
  max-height: 100%;
}

.link1 {
	position: absolute;
	left: 22%;
	top: 85%;
	width: 13%;
	height: 10%;
	/*background-color: rgba(0, 0, 0, .25);
	border: 1px solid green;*/
}

.link2 {
	position: absolute;
	left: 64%;
	top: 85%;
	width: 15%;
	height: 10%;
	/*background-color: rgba(0, 0, 0, .25);
	border: 1px solid green;*/
}

.link1:hover {
	position: absolute;
  width: 100%;
  height: 100%;
	left: 0%;
	top: 0%;
  background: url("./images/GOnet_Region_Selection.png") no-repeat center center fixed;
  background-size: contain;
  display: flex;
	}

	.link2:hover {
		/*border: 1px solid red;*/
		}

		/* Medium devices (tablets, 768px and up)*/
		@media (min-width: 768px) {
				.link1 {
					position: absolute;
					left: 18%;
					top: 85%;
					width: 14%;
					height: 10%;
					/*background-color: rgba(0, 0, 0, .25);
					border: 1px solid red;*/
				}

				.link2 {
					position: absolute;
					left: 67%;
					top: 85%;
					width: 16%;
					height: 10%;
					/*background-color: rgba(0, 0, 0, .25);
					border: 1px solid red;*/
				}
			}

		@media (min-width: 992px) {
				.link1 {
					position: absolute;
					left: 22%;
					top: 85%;
					width: 13%;
					height: 10%;
					/*background-color: rgba(0, 0, 0, .25);
					border: 1px solid green;*/
				}

				.link2 {
					position: absolute;
					left: 64%;
					top: 85%;
					width: 15%;
					height: 10%;
					/*background-color: rgba(0, 0, 0, .25);
					border: 1px solid green;*/
				}
			}
